<template>
  <div>
    <!-- <el-button type="primary" @click="downloadImg()" plain style="position: relative; top: -10px">下载</el-button> -->
    <div class="canvas_easy_picture" id="easy_picture" ref="imageTofile">
      <div class="canvas_easy_top">
        <div class="first_title">
          <div class="first_title_left">
            <span>专属智参周报</span>
          </div>
          <div class="first_title_right">
            <img
              v-if="companyinfo.companyLogo"
              width="24"
              height="24"
              :src="companyinfo.companyLogo"
              alt=""
            />
            <img
              v-else
              width="24"
              height="24"
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%203237.png"
              alt=""
            />
            <span>{{ companyinfo.companyFullName }}</span>
          </div>
        </div>
      </div>
      <div class="card" v-loading="loading">
        <div class="card_box">
          <div class="card_img">
            <img
              :src="weeklyInfo.cover"
              alt=""
            />
          </div>
          <div class="weektype_warp">
            <div v-for="(item, index) in typeList" :key="index">
              <div :class="index == 0 ? 'weekly_back' : 'weekly_type'">
                <span>{{ item.type }}</span>
                <span>({{ item.num }})</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card_data">
          <div v-for="(el, index) in dataList" :key="index">
            <div class="boss_datalist" v-if="el.type == '集市内容'&&el.list.length">
              <div class="boss_datalist_top">
                <div>
                  <span>集市内容</span>
                </div>
              </div>
              <div class="boss_datalist_top_hezi" v-for="(item, i) in el.list" :key="i">
                <div class="boss_datalist_top_lex">
                  <div class="boss_datalist_top_box">
                    <div class="boss_datalist_top_box_height">
                      <div class="boss_datalist_top_lex_name">
                        <div
                          class="boss_datalist_top_lex_name_type"
                          v-if="item.informationType == 4"
                        >
                          <span class="title_type4">数智案例</span>
                          <span class="title_type4po"></span>
                        </div>
                        <div
                          v-if="item.informationType == 5"
                          class="boss_datalist_top_lex_name_type"
                        >
                          <span class="title_type4">数智产品</span>
                          <span class="title_type4po"></span>
                        </div>
                        <div
                          v-if="item.informationType == 6"
                          class="boss_datalist_top_lex_name_type"
                        >
                          <span class="title_type4">服务商</span>
                          <span class="title_type4po"></span>
                        </div>

                        <div class="informationName">
                          <span>{{ item.informationName }}</span>
                        </div>
                      </div>
                      <div class="informationBrief_flex">
                        <div class="informationBrief">
                          <span>{{ item.informationBrief }}</span>
                        </div>

                        <div class="cover_image">
                          <img :src="item.cover" alt="" />
                        </div>
                      </div>
                      <div class="label" v-if="item.labelList">
                        <img
                          width="15"
                          height="15"
                          src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Snipaste_2023-08-21_14-00-58.png"
                          alt=""
                        />

                        <span v-for="(o, i) in item.labelList.slice(0, 2)" :key="i" class="alabel"
                          >#{{ o }}
                        </span>
                      </div>
                      <view class="last_type">
                        <img
                          v-if="item.zxCompanyLogo"
                          class="radius_img"
                          :src="item.zxCompanyLogo"
                        />
                        <span
                          v-if="item.zxCompanyName"
                          class="zxCompanyName_color"
                          style="margin-left: 10rpx"
                          >{{ item.zxCompanyName }}</span
                        >
                      </view>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="boss_datalist" v-if="el.type == '行业情报'&&el.list.length">
              <div class="boss_datalist_top color_backa">
                <div>
                  <span>行业情报</span>
                </div>
              </div>
              <div class="boss_datalist_top_hezi" v-for="(item, i) in el.list" :key="i">
                <div class="boss_datalist_top_lex">
                  <div class="boss_datalist_top_box">
                    <div class="boss_datalist_top_box_height">
                      <div class="boss_datalist_top_lex_name">
                        <div
                          v-if="item.informationType == 3"
                          class="boss_datalist_top_lex_name_type"
                        >
                          <span class="title_type3">行业动态</span>
                          <span class="title_type3po"></span>
                        </div>

                        <div class="informationName">
                          <span>{{ item.informationName }}</span>
                        </div>
                      </div>
                      <div class="informationBrief_flex">
                        <div class="informationBrief">
                          <span>{{ item.informationBrief }}</span>
                        </div>

                        <div class="cover_image">
                          <img :src="item.cover" alt="" />
                        </div>
                      </div>
                      <div class="label" v-if="item.labelList">
                        <img
                          width="15"
                          height="15"
                          src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Snipaste_2023-08-21_14-00-58.png"
                          alt=""
                        />

                        <span v-for="(o, i) in item.labelList.slice(0, 2)" :key="i" class="alabel"
                          >#{{ o }}
                        </span>
                      </div>
                      <div class="last_type">
                        <span class="zxCompanyName_color">{{ item.resource }}</span>
                        <span class="hang_names"></span>
                        <span style="margin-left: 10rpx" class="zxCompanyName_color">{{
                          item.issueTime
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="boss_datalist" v-if="el.type == '转型知识'&&el.list.length">
              <div class="boss_datalist_top color_back">
                <div>
                  <span>转型知识</span>
                </div>
              </div>
              <div class="boss_datalist_top_hezi" v-for="(item, i) in el.list" :key="i">
                <div class="boss_datalist_top_lex">
                  <div class="boss_datalist_top_box">
                    <div class="boss_datalist_top_box_height">
                      <div class="boss_datalist_top_lex_name">
                        <div
                          v-if="item.informationType == 1"
                          class="boss_datalist_top_lex_name_type"
                        >
                          <span class="title_text1">应用场景</span>
                          <span class="title_text1po"></span>
                        </div>
                        <div
                          v-if="item.informationType == 2"
                          class="boss_datalist_top_lex_name_type"
                        >
                          <span class="title_text1">数智技术</span>
                          <span class="title_text1po"></span>
                        </div>

                        <div class="informationName">
                          <span>{{ item.informationName }}</span>
                        </div>
                      </div>
                      <div class="informationBrief_flex">
                        <div class="informationBrief">
                          <span>{{ item.informationBrief }}</span>
                        </div>

                        <div class="cover_image">
                          <img :src="item.cover" alt="" />
                        </div>
                      </div>
                      <div class="label" v-if="item.labelList">
                        <img
                          width="15"
                          height="15"
                          src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Snipaste_2023-08-21_14-00-58.png"
                          alt=""
                        />

                        <span v-for="(o, i) in item.labelList.slice(0, 2)" :key="i" class="alabel"
                          >#{{ o }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="boss_datalist" v-if="el.type == '专家'&&el.list.length">
              <div class="boss_datalist_top color_backa">
                <div>
                  <span>数智专家</span>
                </div>
                <div>
                  <span>更多</span>
                </div>
              </div>
              <div class="boss_datalist_top_hezi" v-for="(item, i) in el.list" :key="i">
                <div class="boss_datalist_top_lex">
                  <div class="boss_datalist_top_box">
                    <div class="boss_datalist_top_box_height">
                      <div class="boss_datalist_top_lex_name">
                        <div class="boss_datalist_top_lex_name_type">
                          <span class="idType1">平台专家</span>
                          <span class="idType1po"></span>
                        </div>
                        <div class="informationName">
                          <span>{{ item.informationName }}</span>
                        </div>
                      </div>
                      <div class="informationBrief_flex">
                        <div class="informationBrief">
                          <span>{{ item.informationBrief }}</span>
                        </div>

                        <div class="cover_image">
                          <img :src="item.cover" alt="" />
                        </div>
                      </div>
                      <div class="label" v-if="item.labelList">
                        <img
                          width="15"
                          height="15"
                          src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Snipaste_2023-08-21_14-00-58.png"
                          alt=""
                        />

                        <span v-for="(o, i) in item.labelList" :key="i" class="alabel"
                          >#{{ o }}
                        </span>
                      </div>
                      <div class="last_type" v-if="item.chatType">
                        <div v-for="(el, indexs) in item.chatType" :key="indexs">
                          <text class="zxCompanyName_color">{{ el }}</text>
                          <text
                            v-if="indexs == 0 && item.chatType.length == 2"
                            class="hang_name"
                          ></text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="boss_datalist" v-if="el.type == '活动'&&el.list.length">
              <div class="boss_datalist_top color_back">
                <div>
                  <span>数智活动</span>
                </div>
                <div>
                  <span>更多</span>
                </div>
              </div>
              <div class="boss_datalist_top_hezi" v-for="(item, i) in el.list" :key="i">
                <div class="boss_datalist_top_lex">
                  <div class="boss_datalist_top_box">
                    <div class="boss_datalist_top_box_height">
                      <div class="boss_datalist_top_lex_name">
                        <div class="boss_datalist_top_lex_name_type">
                          <span class="idType3">数智活动</span>
                          <span class="idType3po"></span>
                        </div>
                        <div class="informationName">
                          <span>{{ item.informationName }}</span>
                        </div>
                      </div>
                      <div class="informationBrief_flex">
                        <div class="informationBrief">
                          <span>{{ item.informationBrief }}</span>
                        </div>

                        <div class="cover_image">
                          <img :src="item.cover" alt="" />
                        </div>
                      </div>
                      <div class="label" v-if="item.labelList">
                        <img
                          width="15"
                          height="15"
                          src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Snipaste_2023-08-21_14-00-58.png"
                          alt=""
                        />

                        <span v-for="(o, i) in item.labelList.slice(0, 2)" :key="i" class="alabel"
                          >#{{ o }}
                        </span>
                      </div>
                      <div class="last_type">
                        <span v-if="item.activityType==1" class="zxCompanyName_color"
                          >线上活动</span
                        >
                        <span v-if="item.activityType==2" class="zxCompanyName_color"
                          >线下活动</span
                        >
                        <span class="hang_names"></span>
                        <span class="zxCompanyName_color"
                          >时间：{{ item.activityStartTime }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="boss_datalist" v-if="el.type == '报告'&&el.list.length">
              <div class="boss_datalist_top">
                <div>
                  <span>数智报告</span>
                </div>
                <div>
                  <span>更多</span>
                </div>
              </div>
              <div class="boss_datalist_top_hezi" v-for="(item, i) in el.list" :key="i">
                <div class="boss_datalist_top_lex">
                  <div class="boss_datalist_top_box">
                    <div class="boss_datalist_top_box_height">
                      <div class="boss_datalist_top_lex_name">
                        <div class="boss_datalist_top_lex_name_type">
                          <span class="idType2">行业报告</span>
                          <span class="idType2po"></span>
                        </div>
                        <div class="informationName">
                          <span>{{ item.informationName }}</span>
                        </div>
                      </div>
                      <div class="informationBrief_flex">
                        <div class="informationBrief">
                          <span>{{ item.informationBrief }}</span>
                        </div>

                        <div class="cover_image">
                          <img :src="item.cover" alt="" />
                        </div>
                      </div>
                      <div class="label" v-if="item.labelList">
                        <img
                          width="15"
                          height="15"
                          src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Snipaste_2023-08-21_14-00-58.png"
                          alt=""
                        />
                        <span v-for="(o, i) in item.labelList" :key="i" class="alabel"
                          >#{{ o }}
                        </span>
                      </div>
                      <div class="last_type">
                        <span class="zxCompanyName_color">{{ item.releaseMechanism }}</span>
                        <span v-if="item.releaseMechanism" class="hang_names"></span>
                        <span v-if="item.reportNumber > 1" class="zxCompanyName_color"
                          >{{ item.reportNumber }}份报告</span
                        >
                        <span v-if="item.reportNumber == 1" class="zxCompanyName_color"
                          >{{ item.pages }}页</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="code_flex">
            <div>
              <img class="QRCode" :src="QRCode" alt="">
            </div>
            <div>
              <div class="zc_color">
                <span>智参，企业数智化转型的“专属参谋”</span>
              </div>
              <div>
                <span style="color: #999999;">识别小程序码可查看更多详细内容</span>
              </div>
            </div>
          </div>
        </div>
       
      </div>

      <a href="" style="display: none" id="download_link"></a>
    </div>
  </div>
</template>

<script>
import { userTabloidPage, getTabloid,basicQrcode ,getTabloidContentNum} from '@/api/weekly'
import html2canvas from 'html2canvas'
export default {
  name: 'EasyImageCanvas',
  props: {
    tabloid: {
      type: String
    },

    bol: {
      type: Boolean
    },
   
    companyId: {
      type: String
    },
    companyinfo: {
      type: Object
    }
  },
  data() {
    return {
      loading:false,
      QRCode:"",
      weeklyInfo: {}, //周报详情
      dataList: [
        {
          type: '集市内容',
          list: []
        },
        {
          type: '行业情报',
          list: []
        },
        {
          type: '转型知识',
          list: []
        },
        {
          type: '专家',
          list: []
        },
        {
          type: '活动',
          list: []
        },
        {
          type: '报告',
          list: []
        }
      ],
      typeList: [
        {
          type: '集市内容',
          num: 20
        },
        {
          type: '行业情报',
          num: 20
        },
        {
          type: '转型知识',
          num: 20
        },
        {
          type: '数智专家',
          num: 20
        },
        {
          type: '数智活动',
          num: 20
        },
        {
          type: '数智报告',
          num: 20
        }
      ],
      htmlUrl: '', //html2canvas绘制好的图片地址
      isShow: false //在页面dom渲染完毕后让图片显示在用户界面
    }
  },
  created() {
     this.getTabloid()
   
  },
  watch: {
    bol: {
      handler(newVal) {
        console.log(newVal)
        if (newVal) { 
         this.dataList=[
        {
          type: '集市内容',
          list: []
        },
        {
          type: '行业情报',
          list: []
        },
        {
          type: '转型知识',
          list: []
        },
        {
          type: '专家',
          list: []
        },
        {
          type: '活动',
          list: []
        },
        {
          type: '报告',
          list: []
        }
      ],
            this.getTabloid()

        }
      },
      immediate: false // 第一次传值是否执行handler
    }
  },

  methods: {
        //实体数量
  async  getTabloidContentNum(tabloidTime){
      const query={
        companyId:this.companyId,
        tabloidTime,
      }
    
      const {data:res}=await getTabloidContentNum(query)
      if (res.resultCode === 200) {
        this.typeList.forEach(el=>{
          if(el.type=='数智活动'){
            el.num=res.data.activityNum
          }
          if(el.type=='集市内容'){
            el.num=res.data.marketNum
          }
          if(el.type=='行业情报'){
            el.num=res.data.industryNum
          }
          if(el.type=='转型知识'){
            el.num=res.data.knowledgeNum
          }
          if(el.type=='数智专家'){
            el.num=res.data.specialistNum
          }
          if(el.type=='数智报告'){
            el.num=res.data.reportNum
          }
          

        })
    }
    },
    //获取二维码
   async basicQrcode(){
    const query={
        sceneStr:'pages/user/weekly_info/index?id='+this.tabloid
      }
    const res=await basicQrcode(query)
      if(res.data){
        this.QRCode=res.data
      
        setTimeout(()=>{
          this.loading=false
          this.downloadImg()
        },1000)
      }
    },
    //周报详情
    async getTabloid() {
      const { data: res } = await getTabloid({ id: this.tabloid })

      if (res.resultCode === 200) {
        this.weeklyInfo = res.data
        this.userTabloidPage(this.weeklyInfo.tabloidTime)
        this.getTabloidContentNum(this.weeklyInfo.tabloidTime)
      }
    },
    async userTabloidPage(tabloidTime) {
      this.loading=true
      const query = {
        companyId: this.companyId,
        tabloidTime
      }
      const { data: res } = await userTabloidPage(query)
      if (res.resultCode === 200) {
        let list = res.data

        list.forEach((el) => {
          this.dataList.forEach((item) => {
            if (el.idType == '资讯') {
              if (el.informationType >= 4 && item.type == '集市内容') {
                item.list.push(el)
              }
              if (el.informationType == 3 && item.type == '行业情报') {
                item.list.push(el)
              }
              if (el.informationType < 3 && item.type == '转型知识') {
                item.list.push(el)
              }
            } else {
              if (el.idType == item.type) {
                item.list.push(el)
              }
            }
            if (item.list.length) {
              item.list = item.list.slice(0, 1)
            }
          })
        })
        console.log( this.dataList);
        this.basicQrcode()
      }
    },
    // 画图并下载
    downloadImg() {
      const shareContent = document.getElementById('easy_picture') //需要截图的包裹的（原生的）DOM 对象
      // const width = shareContent.offsetWidth //获取dom 宽度
      const width = shareContent.offsetWidth
      const height = shareContent.offsetHeight //获取dom 高度
      let canvas = document.createElement('canvas') //创建一个canvas节点
      // const scale = window.devicePixelRatio //定义任意放大倍数 支持小数
      let scale = 2 //定义任意放大倍数 支持小数
      canvas.width = width * scale //定义canvas 宽度 * 缩放
      canvas.height = height * scale //定义canvas高度 *缩放
      canvas.style.width = width + 'px'
      canvas.style.height = height + 'px'
      canvas.getContext('2d').scale(1, 1) //获取context,设置scale

      let opts = {
        // dpi: window.devicePixelRatio * 2,
        dpi: 500,
        scale: scale, // 添加的scale 参数
        useCORS: true,
        canvas: canvas, //自定义 canvas
        logging: true //日志开关
        // width: width, //dom 原始宽度
        // height: height, //dom 原始高度
        // backgroundColor: 'null',
      }
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.imageTofile, opts).then((canvas) => {
        // let url = canvas.toDataURL('image/png')
        // this.htmlUrl = url
        // this.$nextTick(() => {
        //   this.isShow = true //绘图完毕让图片显示
        // })
        if (window.navigator.msSaveBlob) {
          let blob = canvas.msToBlob()
          /*这里直接改后缀修改格式*/
          window.navigator.msSaveBlob(blob, 'image/png')
        } else {
          let url = canvas.toDataURL() //把canvas转成base64
          // 写一个隐藏的a标签，借助a标签的download属性下载图片
          document.querySelector('#download_link').href = url
          document.querySelector('#download_link').download = 'image'
          document.querySelector('#download_link').click()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.canvas_easy_picture {
  width: 400px;
  box-sizing: border-box;
  background-image: url('https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Snipaste_2023-08-08_17-58-46.png');
  background-size: 100% 40%;
  background-repeat: no-repeat;

  .canvas_easy_top {
    padding: 20px;
  }
  .first_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    color: #212121;
    .first_title_left {
      width: 165px;
      height: 60px;
      font-size: 22px;
      font-weight: 550;
      color: #ffffff;
      background-image: url('https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E7%B1%BB%E5%9E%8B%E5%AE%9A%E4%BD%8D.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      line-height: 50px;
      position: relative;
      top: 10px;
    }
    .first_title_right {
      background: #bacbfc;
      border-radius: 30px;
      padding: 5px 8px;
      text-align: center;
      font-size: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        border-radius: 24px;
      }
    }
  }
  .card {
    position: relative;
    top: -25px;
    z-index: 0;
    .card_box {
      padding: 0px 20px;
    }
    .card_img {
      width: 100%;
      height: 160px;

      img {
        width: 100%;
        height: 160px;
      }
    }
    .weektype_warp {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .weekly_type {
        margin-bottom: 10px;
        width: 108px;
        height: 32px;
        background: #ffffff;
        border-radius: 32px;
        color: #212121;
        text-align: center;
        line-height: 32px;
      }
    }
    .weekly_back {
      width: 108px;
      height: 35px;
      color: #fff;
      text-align: center;
      line-height: 32px;
      background: none;
      background-image: url('https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E6%99%BA%E5%8F%82%E5%91%A8%E6%8A%A5.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .last_type {
    display: flex;
    align-items: center;
    position: relative;
    top: 10px;
    .zxCompanyName_color {
      color: #4e93fb;
      font-size: 12px;
    }
    .hang_names {
      display: inline-block;
      width: 0px;
      height: 10px;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;
      border: 1px solid #d4d4d4;
    }
    .radius_img {
      width: 13px;
      height: 13px;
      border-radius: 13px;
    }
  }
  .card_data {
    padding: 0px 5px;
    padding-bottom: 20px;
 
    background: linear-gradient(#cadffb 0%, #cadffb 1%, #d6e7fc 2%, #f6f6f6 97%);
 
    .code_flex{
      padding:0px 15px;
      background: #fff;
      height: 80px;
      border-radius: 15px;
      display: flex;
      align-items: center;
     
      .QRCode{
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }
      .zc_color{
        margin-bottom: 10px;
        color: #333333;
      }
    }
  }
  .boss_datalist {
    border-radius: 10px;
    background: #fff;
    margin-bottom: 20px;
    margin-top: 10px;
    .boss_datalist_top {
      background: linear-gradient(#d1e0fd 0%, #cfe3fc 5%, #d4e7ff 10%, #ffff 90%);
      display: flex;
      justify-content: space-between;
      padding: 0px 25px;
      padding-top: 20px;
      border-radius: 10px;
      font-weight: 550;
      font-size: 16px;
      color: #212121;
    }
    .color_back {
      background: linear-gradient(#fafad9 0%, #faf0dd 10%, #fdf7d5 20%, #ffff 90%);
    }
    .color_backa {
      background: linear-gradient(#c8fae0 0%, #bffcde 10%, #c0ffe1 20%, #ffff 90%);
    }
    .boss_datalist_top_hezi {
      padding: 20px;
    }
    .boss_datalist_top_lex {
      width: 100%;
      height: 129px;
      border-radius: 8px;
      background: #fff;
      padding: 10px;
      border: 1px solid #f2f2f2;
      .informationBrief {
        width: 200px;
        -webkit-line-clamp: 2;
        white-space: wrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .boss_datalist_top_lex_name {
        display: flex;
        .informationName {
          font-size: 15px;
          font-weight: 550;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: #212121;
          margin-left: 10px;
        }
        .boss_datalist_top_lex_name_type {
          position: relative;
        }
        .title_type4 {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
          background: #00c237;
          border: 0.5px solid #00c237;
          border-radius: 45px;
          z-index: 99;
          position: relative;
        }
        .title_type4po {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          display: inline-block;
          background: #249544;
          border: 0.5px solid #249544;
          border-radius: 45px;
          position: absolute;
          top: 3px;
          left: 3px;
          z-index: 9;
        }
        .title_type3 {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          line-height: 20px;
          text-align: center;
          display: inline-block;

          background: #3fc1f8;
          border: 0.5px solid #3fc1f8;
          border-radius: 45px;
          z-index: 99;
          position: relative;
        }
        .title_type3po {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          display: inline-block;
          background: #00acf7;
          border: 0.5px solid #00acf7;
          border-radius: 45px;
          position: absolute;
          top: 3px;
          left: 3px;
          z-index: 9;
        }

        .title_text1 {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
          background: #518bff;
          border: 0.5px solid #518bff;
          border-radius: 45px;
          z-index: 99;
          position: relative;
        }
        .title_text1po {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          display: inline-block;
          background: #3c7dff;
          border: 0.5px solid #3c7dff;
          border-radius: 45px;
          position: absolute;
          top: 3px;
          left: 3px;
          z-index: 9;
        }
        .idType1 {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
          background: #faba53;
          border: 0.5px solid #faba53;
          border-radius: 45px;
          z-index: 99;
          position: relative;
        }
        .idType1po {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          display: inline-block;
          background: #f2ab37;
          border: 0.5px solid #f2ab37;
          border-radius: 45px;
          position: absolute;
          top: 3px;
          left: 3px;
          z-index: 9;
        }
        .idType2 {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
          background: #4cd8ad;
          border: 0.5px solid #4cd8ad;
          border-radius: 45px;
          z-index: 99;
          position: relative;
        }
        .idType2po {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          display: inline-block;
          background: #28c393;
          border: 0.5px solid #28c393;
          border-radius: 45px;
          position: absolute;
          top: 3px;
          left: 3px;
          z-index: 9;
        }
        .idType3 {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
          background: #1b39ff;
          border: 0.5px solid #1b39ff;
          border-radius: 45px;
          z-index: 99;
          position: relative;
        }
        .idType3po {
          font-size: 12px;
          color: #ffffff;
          height: 20px;
          width: 60px;
          display: inline-block;
          background: #001ddb;
          border: 0.5px solid #001ddb;
          border-radius: 45px;
          position: absolute;
          top: 3px;
          left: 3px;
          z-index: 9;
        }
      }
      .boss_datalist_top_box_height {
        height: 129px;
      }
      .boss_datalist_top_box {
        width: 100%;
        height: 129px;
        .informationBrief_flex {
          display: flex;
          justify-content: space-between;
          position: relative;
          margin-top: 5px;
        }
        .label {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #83889c;
          /* margin-top: 15rpx; */
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        .alabel {
          display: inline-block;
          margin-left: 5px;
          font-size: 10px;
          color: #83889c;
        }
        .cover_image {
          width: 112px;
          height: 62px;
          img {
            width: 112px;
            height: 62px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
