<template>
  <div class="EnterpriseContent">
    <div class="order_top">
      <div class="storeCenter_companyinfo_top_left">
        <img v-if="companyinfo.companyLogo" :src="companyinfo.companyLogo" alt="" />
        <img v-else src="../../../assets/image/Group 3237.png" alt="" />
        <div class="storeCenter_companyinfo_top_left_flex">
          <div style="display: flex; align-companyinfos: center">
            <h5 style="display: inline-block; margin: 0; font-size: 20px">
              {{ companyinfo.companyFullName }}
            </h5>
          </div>
          <div class="storeCenter_companyinfo_top_left_flex_tag">
            <span v-if="companyinfo.labelList" style="display: flex">
              <span v-for="(o, index) in companyinfo.labelList.slice(0, 3)" :key="index">
                <span v-if="index < 3" class="office">{{ o }}</span>
              </span>
            </span>
            <el-popover placement="right" width="430" trigger="hover">
              <div
                class="hidden_label_box"
                style="
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-content: center;
                  flex-wrap: wrap;
                "
              >
                <span
                  class="office"
                  style="
                    border: 1px solid #559eff;
                    color: #4e93fb;
                    box-sizing: border-box;
                    padding: 1px 6px;
                    display: block;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 4px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                  "
                  v-for="(o, indexs) in companyinfo.labelList"
                  :key="indexs"
                  >{{ o }}</span
                >
              </div>
              <span
                class="office"
                slot="reference"
                v-if="companyinfo.labelList && companyinfo.labelList.length >= 3"
                >{{ companyinfo.labelList.length }}+</span
              >
            </el-popover>
          </div>
          <div class="address">
            <span style="padding-left: 0px"> {{ companyinfo.legalPerson }}</span>
            <span> {{ companyinfo.companyPhone }}</span>
            <span> {{ companyinfo.province }}-{{ companyinfo.city }}</span>
            <span style="color: #4E93FB;">{{ companyinfo.officialWebsite }}</span>
          </div>
        </div>
      </div>
      <div class="buttom_info">
        <el-switch
          @change="updateWeeklyNewspaperStatus(companyinfo)"
          style="margin-right: 20px"
          v-model="companyinfo.weeklyNewspaperStatus"
          :active-text="companyinfo.weeklyNewspaperStatus ? '已开启周报' : '未开启周报'"
        >
        </el-switch>
      </div>
    </div>

    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="90px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label=" " label-width="0px">
              <div class="input-with">
                <div>
                  <el-input
                    placeholder="请输入名称"
                    v-model="queryInfo.queryConditions"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
            <el-form-item label=" " label-width="0px">
              <el-radio-group v-model="queryInfo.filterType" @change="search">
                <el-radio  label="">全部 </el-radio>
                <el-radio :label="1">只看已查看 </el-radio>
                <el-radio :label="2">只查看已分享</el-radio>
                <el-radio :label="3">只查看已推送服务信息</el-radio>
                <el-radio :label="4">只查看已推送短信消息</el-radio>
                
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="tab_txt">
        <el-table
          ref="multipleTable"
          :data="listData"
          tooltip-effect="dark"
          style="width: 100%"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中..."
          @sort-change="changeSort"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="编号" align="center" width="100px" key="id" prop="id">
          </el-table-column>

          <el-table-column label="封面图" key="cover" prop="cover" align="center">
            <template v-slot="{ row }">
              <div class="imgbox">
                <div class="img">
                  <img width="69" height="48" :src="row.cover" alt="" />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="一句话介绍"
            align="center"
            width="170"
            key="tabloidIntro"
            prop="tabloidIntro"
          >
          </el-table-column>
          <el-table-column
            label="推送时间"
            key="pushTime"
            sortable
            align="center"
            prop="pushTime"
          ></el-table-column>
          <el-table-column label="短信推送" prop="pushShortNote" align="center">
            <template v-slot="{ row }">
              <span v-if="row.pushShortNote">已推送</span>
              <span v-else>未推送</span>
            </template>
          </el-table-column>
          <el-table-column label="服务消息推送" prop="pushWxNotify" align="center">
            <template v-slot="{ row }">
              <span v-if="row.pushWxNotify">已推送</span>
              <span v-else>未推送</span>
            </template>
          </el-table-column>

          <el-table-column
            label="分享"
            width="165"
            key="shareResultList"
            prop="shareResultList"
            align="center"
          >
            <template v-slot="{ row }">
              <div v-if="row.shareResultList">
                <div :class="[row.spread ? 'shareResultList' : 'spreadwrap']">
                  <div
                    class="tooltip_box"
                    v-for="(item, index) in row.shareResultList"
                    :key="index"
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        <div>{{ item.shareName }}</div>
                        <div style="margin-top: 5px">
                          <span>{{ item.position }}|{{ item.phone }}</span>
                        </div>
                      </div>
                      <el-button type="text">
                        {{ item.shareName }}
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="row.shareResultList.length > 2">
                  <div v-show="row.spread == false" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary" plain
                      >展开<el-icon class="el-icon-arrow-down"></el-icon
                    ></el-button>
                  </div>
                  <div v-show="row.spread == true" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary"
                      >收起<el-icon class="el-icon-arrow-up"></el-icon
                    ></el-button>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="分享次数"
            key="shareNum"
            align="center"
            sortable
            prop="shareNum"
          ></el-table-column>
          <el-table-column
            label="最近分享时间"
            key="lastShareTime"
            align="center"
            sortable
            width="130"
            prop="lastShareTime"
          ></el-table-column>
          <el-table-column
            width="165"
            label="查看"
            prop="companyTabloidScanResultList"
            key="companyTabloidScanResultList"
            align="center"
          >
            <template v-slot="{ row }">
              <div v-if="row.companyTabloidScanResultList">
                <div :class="[row.spread ? 'shareResultList' : 'spreadwrap']">
                  <div
                    class="tooltip_box"
                    v-for="(item, index) in row.companyTabloidScanResultList"
                    :key="index"
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        <div>{{ item.userName }}</div>
                        <div style="margin-top: 5px">
                          <span>{{ item.position }}|{{ item.phone }}</span>
                        </div>
                      </div>
                      <el-button type="text">
                        {{ item.userName }}
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="row.companyTabloidScanResultList.length > 2">
                  <div v-show="row.spread == false" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary" plain
                      >展开<el-icon class="el-icon-arrow-down"></el-icon
                    ></el-button>
                  </div>
                  <div v-show="row.spread == true" @click="spreadText(row)" class="spread-btn">
                    <el-button size="mini" type="primary"
                      >收起<el-icon class="el-icon-arrow-up"></el-icon
                    ></el-button>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="查看次数" prop="scanNum" align="center"> </el-table-column>

          <el-table-column
            label="最近查看时间"
            key="lastScanTime"
            align="center"
            sortable
            width="130"
            prop="lastScanTime"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template v-slot="{ row }">
              <div class="btn">
                <span class="pass" @click="Jumpinfo(row)" style="margin-right: 10px">详情</span>
                <span class="pass" @click="downloadSimpleImage(row)">下载图片</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="Pagination">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog title="预览图片" width="450px" :visible.sync="easyDialogVisible" append-to-body>
      <div class="dialog_box">
        <EasyWeekCanvas :companyinfo="companyinfo" :companyId="companyId" :tabloid="tabloid"  :bol="easyDialogVisible" ref="downloadEasyImage" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { companyTabloidPage, updateWeeklyNewspaperStatus, getCompanyDetail } from '@/api/datement'
import {getQRCode } from '@/api/activity'

import EasyWeekCanvas from './EasywWeekCanvas.vue'

const defaultQueryInfo = Object.freeze({
  companyId: '', //企业主键
  filterType: null, //1==只看已查看 2==只查看已分享 3==只查看已推送服务信息 4==只查看已推送短信消息
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  queryConditions: '', //模糊查询字段
  sortParam: 1 //1->推送时间倒序;2->推送时间正序;3->分享次数倒序;4->分享次数正序;5->最近分享时间倒序;6->最近分享时间正序;7->最近查看时间倒序;8->最近查看时间正序
})
export default {
  name: 'weekly',
  components: { EasyWeekCanvas },
  computed: {
    companyId() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      value1: false,
      ids: [],
      idsList: [],
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      companyinfo: {}, //企业信息
      tabloid:'',//周报id
      //实体数量
      contentNum: {},
      // 遮罩层
      loading: false,
      easyDialogVisible: false,
      // 总条数
      total: 0,
     
    }
  },
  created() {
    this.search()
    this.getcompany()
  },

  methods: {
   async downloadSimpleImage(row) {
    this.easyDialogVisible = true
    this.tabloid=row.tabloidId
    
    },
    //查询企业信息回显
    async getcompany() {
      const { data: res } = await getCompanyDetail({ id: this.companyId })
      if (res.resultCode == 200) {
        this.companyinfo = res.data
      }
    },
    //启动关闭
    async updateWeeklyNewspaperStatus(item) {
      const query = {
        id: item.id,
        value: item.weeklyNewspaperStatus
      }
      const { data: res } = await updateWeeklyNewspaperStatus(query)
      if (res.resultCode === 200) {
        this.getcompany()
        this.$message.success('操作成功')
      }
    },
    //类型选择
    storeClick(id) {
      this.queryInfo.type = id
      this.indexType = id
      this.search()
    },
    //内容数据一览
    async search() {
      this.loading = true
      this.queryInfo.companyId = this.companyId
      const { data: res } = await companyTabloidPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.listData = res.data.list
        this.listData.forEach((el) => {
          if (el.labels) {
            el.labels = el.labels.split('~')
          }
          this.$set(el, 'spread', false)
        })

        this.total = res.data.total
        this.loading = false
      } else {
        this.loading = false
      }
    },
    //跳转详情
    Jumpinfo(row) {
      console.log(row);
      this.$router.push({
        path: '/customer/EnterpriseContent',
        query: {
          tabloidId: row.tabloidId,
          id:this.companyId,
        }
      })
    },
    //企业基本信息
    companyInfo() {
      this.$router.push({
        path: '/customer/addenterprise',
        query: { id: this.companyId, headTabActive: 1 }
      })
    },
    // table表格按钮的选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.foreignKey)
    },
    //展开收缩
    spreadText(row) {
      row.spread = !row.spread
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'lastScanTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.sortParam = '8'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.sortParam = '7'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.sortParam = '1'
          this.search()
        }
      }
      if (val.prop == 'lastShareTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.sortParam = '6'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.sortParam = '5'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.sortParam = '1'
          this.search()
        }
      }
      if (val.prop == 'shareNum') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.sortParam = '4'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.sortParam = '3'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.sortParam = '1'
          this.search()
        }
      }
      if (val.prop == 'pushTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.sortParam = '2'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.sortParam = '1'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.sortParam = '1'
          this.search()
        }
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__inner {
  border: 1px solid #fff;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}
::v-deep .el-dialog__body {
    padding: 10px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}
.EnterpriseContent {
  .order_top {
    background: #ffffff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .primary_buttom {
      border: 1px solid #448aff;
      background: #fff;
    }

    .buttom_info {
      padding-right: 30px;
    }
    .storeCenter_companyinfo_top_left {
      display: flex;
      flex-direction: row;

      img {
        width: 90px;
        height: 90px;
        border-radius: 4px;
        margin-right: 14px;
      }

      .storeCenter_companyinfo_top_left_flex {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .img_commer {
          display: flex;
          border: 1px solid #146aff;
          border-radius: 39px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #146aff;
          }
          .color_img {
            background: #146aff;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }
        .img_commers {
          display: flex;
          border: 1px solid #ff9c00;
          border-radius: 39px;
          margin: 0px 10px;
          .name {
            font-size: 12px;
            line-height: 20px;
            color: #ff9c00;
          }
          .color_img {
            background: #ff9c00;
            height: 20px;
            width: 25px;
            border-radius: 20px;
            position: relative;
            left: -10px;
            img {
              width: 13px;
              height: 13px;
              position: relative;
              left: 5px;
              top: 2px;
            }
          }
        }

        h4 {
          color: #1f2d3d;
          font-size: 26px;
          font-weight: 500;
          margin: 0;
        }

        .storeCenter_companyinfo_top_left_flex_tag {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;
          position: relative;
          left: -10px;

          span {
            box-sizing: border-box;
            padding: 1px 6px;
            display: block;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
          }

          .office {
            border: 1px solid #559eff;
            color: #4e93fb;
            cursor: pointer;
          }

          .support {
            border: 1px solid #fd523f;
            color: #fd523f;
            margin-left: 12px;
          }
        }

        .address {
          display: flex;
          align-companyinfos: center;
          font-size: 14px;
          span {
            padding: 0px 10px;
          }
        }
      }
    }
  }
  .form_info {
    padding: 0px 0px 10px 0px;
    background: #fff;
    margin-top: 20px;
    .comble_txt{
      display: flex;
      align-items: flex-end;
    }
    .form {
      padding-top: 15px;
    }

    .pass {
      color: #4e93fb;
      cursor: pointer;
    }
    .un_pass {
      color: #fd6161;
      cursor: pointer;
    }
    .Pagination {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 50px 0 50px 0;
    }
    .shareResultList {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .tooltip_box {
      padding: 0px 5px;
      display: inline-block;
    }
    .spreadwrap {
      -webkit-line-clamp: 2; //设置几行
      display: -webkit-box; //设置为伸缩盒弹性盒子展示
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //设置超出部分以省略号展示
      -webkit-box-orient: vertical; //伸缩盒弹性盒子的排列方式
    }
    .span_tag {
      color: #4e93fb;
      cursor: pointer;
    }
    .input-with {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
      margin-left: 20px;
      .input-with-select {
        width: 500px;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
